.StdClubsNameSearchField {
  position: absolute !important;
  top: 7px;
  left: 0;
  right: 10px;
}

.StdClubsNameSearchField input {
  margin-left: 10px;
}

.StdPlayersNameSearchField {
  position: absolute !important;
  top: 7px;
  left: -35px;
  right: 10px;
}

.StdPlayersNameSearchField input {
  margin-left: 25px;
}

.StdWPlayersNameSearchField {
  position: absolute !important;
  top: 7px;
  left: -45px;
  right: 10px;
}

@media screen and (min-width: 600px) {
  .StdClubsNameSearchField {
    left: -30px;
  }

  .StdPlayersNameSearchField {
    left: -55px;
  }
}