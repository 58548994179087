.role {
    background-color: #31495A;
}

.role.selected {
    background-color: #04A8A3;
}

.role:hover {
    background-color: #A1CBC2;
}

.role-group {
    fill: transparent;
    pointer-events: auto;
}

.role-group.selected {
    fill: rgba(4, 168, 163, .35);
}

.role-group:hover {
    fill: rgba(49, 73, 90, .35);
}