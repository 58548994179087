@-webkit-keyframes fadeinout {

    0%,
    100% {
        opacity: .5;
    }

    50% {
        opacity: 1;
    }
}

@keyframes fadeinout {

    0%,
    100% {
        opacity: .5;
    }

    50% {
        opacity: 1;
    }
}