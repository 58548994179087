@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900&display=swap');

html,
body,
#root {
  height: 100%;
  font-size: 62.5%; /* 10 px */
  scrollbar-width: none;
  overflow: hidden;
}

@media screen and (min-width: 900px) {
  body {
    scrollbar-width: auto;
    scrollbar-color: #15222B #31495A;
  }
}

h1 {
  color: #04A8A3;
}

a {
  color: inherit;
}

* {
  box-sizing: border-box;
}

.stdScrollable::after {
  content: '';
  position: fixed;
  display: block;
  left: calc(-65px + 80px);
  bottom: 0;
  margin-bottom: 65px;
  margin-left: -16px;
  width: 100%;
  height: 50px;
  background-image: linear-gradient(to bottom, transparent, black);
  pointer-events: none;
  z-index: 2;
}

@media screen and (min-width: 900px) {
  .stdScrollable::after {
    margin-bottom: 0;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 60px #FFFFFF inset !important;
}

::-webkit-scrollbar {
  display: none;
  width: 0;
  height: 0;

}

@media screen and (min-width: 900px) {
  ::-webkit-scrollbar {
    display: initial;
    width: 12px;
    height: 12px;
  }
}

::-webkit-scrollbar-track {
  background-color: #31495A;
  border-radius: 25px;
}

::-webkit-scrollbar-thumb {
  background-color: #15222B;
  border: 3px solid #31495A;
  border-radius: 25px;
}

::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

main::-webkit-scrollbar-track {
  border-radius: 0;
}

main::-webkit-scrollbar-thumb {
  border-radius: 0;
}

.stdVerticalGrid {
  scrollbar-color: #31495A #15222B;
}

.stdVerticalGrid::-webkit-scrollbar-track {
  background-color: #15222B;
}

.stdVerticalGrid::-webkit-scrollbar-thumb {
  background-color: #31495A;
  border: 3px solid #15222B;
}

.stdMaterialReactTable *::-webkit-scrollbar-track {
  border-radius: 0% !important;
}

.stdFilter * {
  scrollbar-color: #DFDFDF #707070;
}

.stdFilter *::-webkit-scrollbar-track {
  background-color: #707070;
}

.stdFilter *::-webkit-scrollbar-thumb {
  background-color: #DFDFDF;
  border: 3px solid #707070;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }

  body {
    color: #FFFFFF;
    background: #000000;
  }
}