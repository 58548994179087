.StdCountCell {
  padding-right: 1px !important;
  padding-left: 6px !important;
}

.StdNameCell {
  padding-left: 10px !important;
  border-right-color: #15222b;
  border-right-style: solid;
  border-right-width: 0;
}

.StdAddWatchlistCell {
  padding-right: 0 !important;
  padding-left: 0 !important;
  border-right-width: 0 !important;
}

.StdEditWatchlistCell {
  border-right-color: #15222b;
  border-right-style: solid;
  border-right-width: 4px;
  border-left-color: #15222b;
  border-left-style: solid;
  border-left-width: 2px;
}

.StdEditWatchlistHeader {
  color: #04A8A3;
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: uppercase;
}

.StdColumnEven {
  color: #A1CBC2;
  --icon-color: #A1CBC2;
  border-right-color: #15222b;
  border-right-style: solid;
  border-right-width: 2px;
  border-left-color: #15222b;
  border-left-style: solid;
  border-left-width: 2px;
}


.StdColumn {
  border-right-color: #15222b;
  border-right-style: solid;
  border-right-width: 2px;
  border-left-color: #15222b;
  border-left-style: solid;
  border-left-width: 2px;
}

.StdColumn:nth-of-type(even) {
  color: #A1CBC2;
  --icon-color: #A1CBC2;
}

.StdColumnEven-last {
  border-right-width: 0;
}

.StdColumnOdd {
  color: #FFFFFF;
  --icon-color: #FFFFFF;
  border-right-color: #15222b;
  border-right-style: solid;
  border-right-width: 2px;
  border-left-color: #15222b;
  border-left-style: solid;
  border-left-width: 2px;
}

.StdColumn:nth-of-type(odd) {
  color: #FFFFFF;
  --icon-color: #FFFFFF;
}

.StdColumnOdd-first {
  border-left-width: 0;
}

.StdColumnOdd-last {
  border-right-width: 0;
}

td.StdColumn:first-of-type {
  border-left-width: 0;
}

td.StdColumn:last-of-type {
  border-right-width: 0;
}

.StdHeader {
  background-color: #223441;
  border-color: #15222b;
  border-style: solid;
  border-width: 2px;
  border-top-width: 4px;
  border-bottom-width: 4px;
}

.StdHeader-first {
  border-left-width: 0;
}

.StdHeader-last {
  border-right-width: 0;
}

.StdHeader:first-of-type {
  border-left-width: 0;
}

.StdHeader:last-of-type {
  border-right-width: 0;
}